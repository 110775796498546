<template>
	<div>
		<CompanySubscriptions :loading-parent="loading" />
		<CompanyInvoices :loading-parent="loading" />
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	data() {
		return {
			loading: true
		}
	},
	components: {
		CompanyInvoices: () => import('@/components/companies/CompanyInvoices.vue'),
		CompanySubscriptions: () => import('@/components/companies/CompanySubscriptions.vue')
	},
	computed: {
		...mapGetters({
			company: 'companies/company'
		})
	},
	created() {
		this.fetchCompany().then(() => {
			this.loading = false
		})
	},
	methods: {
		...mapActions('companies', ['fetchCompany'])
	}
}
</script>
